:root{
    --main-color:#9a2f6f;
}

button {
    height: 45px;
    font-size: 16px !important;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: white;
    font-weight: 400;
    position: relative;
    transition: all 0.5s;
    z-index: 1;
   }
   
   button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: white;
    z-index: -1;
    transition: all 0.5s;
   }
   
   button:hover::before {
    width: 100%;
   }
   
   button:hover {
    color: black !important;
   }
   
   button:active:before {
    background: #b9b9b9 !important;
   }

/* start btn about */
   .ab{
    height: 45px;
    font-size: 16px !important;
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;
    color: var(--main-color);
    font-weight: 400;
    position: relative;
    transition: all 0.5s;
    z-index: 1;
   }
   .ab::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: var(--main-color);
    z-index: -1;
    transition: all 0.5s;
   }
   .ab:hover::before {
    width: 100%;
   }
   .ab:hover {
    color: rgb(255, 252, 252) !important;
   }