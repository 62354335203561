:root{
    --main-color:#9a2f6f;
  }
.hero{
  background-color: var(--main-color);
  background-image: url("./../assets/images/web-background.png");
  background-size: cover;
  height: 100%;
  overflow:hidden;
  display: flex;
  align-items: center;
  
  
  }
  .main-content{
    padding: 110px 0 0 0;
    
  }
  .herpad{
    padding: 0 60px;
  }
  .heroText h1{
    font-size: 80px;
    line-height: 01;
    padding-bottom: 40px !important;
  }
  .hero-img {
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding-top: 20px;
    margin-bottom: -20px;

  }

  .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
    font-weight: bolder;
  }





  /* media screen */

  @media only screen and (max-width:1024px) {
   
    
    .heroText{
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
   
    
    @media only screen and (max-width:768px) {
   
      .heroText h1 {
        font-size: 40px;
        margin-top: -240px;
    }
   
    }
  
  
  /* hero button */
  /* From uiverse.io by @mrhyddenn */
  /* From uiverse.io by @mrhyddenn */
/* From uiverse.io by @mrhyddenn */
button {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  padding: 10px 30px;
  transition: 0.5s;
  position: relative;
  font-size: 17px;
  background: #e331ac;
  border: none;
  color: #fff;
}





button span {
  position: relative;
  z-index: 1;
}

button i {
  position: absolute;

  display: block;
}

button i::before {
  content: '';
  position: absolute;
  border: 2px solid #e331ac;
  width: 7px;
  height: 4px;
  top: -3.5px;
  left: 80%;
  background: #e331ac;
  transform: translateX(-50%);
  transition: 0.5s;
}

button:hover i::before {
  width: 20px;
  left: 20%;
}

button i::after {
  content: '';
  position: absolute;
  border: 2px solid #e331ac;
  width: 7px;
  height: 4px;
  bottom: -3.5px;
  left: 20%;
  background: #e331ac;
  transform: translateX(-50%);
  transition: 0.5s;
}

button:hover i::after {
  width: 20px;
  left: 80%;
}
.page-title .main-page h2.title{
  font-size: 5rem;
  padding: 80px 0;
}