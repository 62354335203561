:root{
  --main-color:#c71fe9;
  --hoverServices:#8c8f932e;
}

  
/* From uiverse.io by @mrhyddenn */
.scrolldown {
  --color: white;
  --sizeX: 30px;
  --sizeY: 50px;
  width: var(--sizeX);
  height: var(--sizeY);
  margin-left: var(sizeX / 2);
  border: calc(var(--sizeX) / 10) solid var(--main-color);
  border-radius: 50px;
  box-sizing: border-box;
  margin-bottom: 16px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 80%;
}

.scrolldown::before {
  content: "";
  position: absolute;
  bottom: 30px;
  left: 50%;
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: var(--main-color);
  border-radius: 100%;
  animation: scrolldown-anim 2s infinite;
  box-sizing: border-box;
  box-shadow: 0px -5px 3px 1px #2a547066;
}

@keyframes scrolldown-anim {
  0% {
    opacity: 0;
    height: 6px;
  }

  40% {
    opacity: 1;
    height: 10px;
  }

  80% {
    transform: translate(0, 20px);
    height: 10px;
    opacity: 0;
  }

  100% {
    height: 3px;
    opacity: 0;
  }
}

.chevrons {
  padding: 6px 0 0 0;
  margin-left: -3px;
  margin-top: 48px;
  width: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chevrondown {
  margin-top: -6px;
  position: relative;
  border: solid var(--main-color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
}

.chevrondown:nth-child(odd) {
  animation: pulse54012 500ms ease infinite alternate;
}

.chevrondown:nth-child(even) {
  animation: pulse54012 500ms ease infinite alternate 250ms;
}

@keyframes pulse54012 {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}
/* btn main */


/* button styling */

/* this is the text, when you hover on button */

/* hover */



/* subscibe */
.input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f3f4;
}

.input {
  min-height: 50px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 1px solid var(--main-color);
  border-radius: 6px 0 0 6px;
  background-color: transparent;
}

.button--submit {
  min-height: 50px;
  padding: .5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: var(--main-color);
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}



.input:focus, .input:focus-visible {
  border-color: #ffa800;
  outline: none;
}
/* services last child */
.tf-section .section-portfolio .style-2 .no-padding-bottom .container .row .list-portfolio:last-child{
  margin-left: 50%;
  transform: translateX(-50%);
}


.myCust{
  position: absolute !important;
  background-color: #483d8b00 !important;
  width: 100%;
  padding: 20px 0;
  top: 0;
  font-size: 16px;
}
.navbar-expand-lg .navbar-nav{
    font-weight: 400;
    text-transform: uppercase;
    margin-left: 80px;
}
.nav-item .nav-link{
  position: relative;
  padding: 0 !important;
}
.nav-item .nav-link::after{
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 2px;
    background: white;
    width: 1px;
    opacity: 0;
    transition: all 0.5s ease;
}
.nav-item:hover .nav-link::after{
  opacity: 1;
  transition: all 0.5s ease;
  width: 100%;
}
.navbar-expand-lg .navbar-nav .nav-link{
  color: #f3f3f4;
}
.navbar-nav li{
  padding: 0 10px;
}
.section-portfolio .list-portfolio .themesflat-portfolio .boxitem .box-item-content .content-box h5.title a{
  text-decoration: none;
}
.slidebar .box-category ul.list-category li a{
  text-decoration: none;
  font-size: 1.5rem;
}
.section-about .intro-content h3.title {
  position: relative;
  z-index: 99;
  display: inline-block;
  text-transform: uppercase;
  color: #11161e;
  font-size: 3rem;
}
h3.title{
  font-size: 2.5rem;
}
.sub-title, .section-blog .heading-top h4.sub-title, .section-about .intro-content h4.sub-title, .section-portfolio .heading-top .sub-title{
  font-size: 2rem;
    font-weight: 500;
}




.menues{
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 3rem;
  padding: 98px 0;
}
.copyright{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-weight: normal;
  vertical-align: baseline;
  background: transparent;
  font-size: 1.4rem;
}
.copyright p{
  margin-bottom: 0 !important;
  font-size: 17px !important;
}
.list-table h3{
  text-align: left;
}
.vidPlay{
  font-size: 30px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.vidPlay:hover{
  color: rgba(255, 255, 255, 0.274);
}
.sub-title,.section-blog .heading-top h4.sub-title,.section-about .intro-content h4.sub-title
,.section-portfolio .heading-top .sub-title{
  font-size: 2rem;
}
/* nav */
.navbar .container-fluid{
  padding: 40px 60px ;
}
/* swipper */
.swiper{
  margin-right: 46px;
}
.swiper-button-next, .swiper-button-prev{
  color: #05b5ff;
}
.swipdiv{
  position: relative;
}
.titlediv{
background-color: var(--main-color);
  color: white
}
.slidebar{
  z-index: 1;
  background-color: white;
}

.bgtitle{
   background: url("./assets/images/about\ us.png") no-repeat;
   background-size: cover;
    background-position: top center;
    padding: 289px 0 223px 0;
}
.srvtitle{
  background: url("./assets/images/our\ services.png") no-repeat;
  background-size: cover;
    background-position: top center;
    padding: 289px 0 223px 0;
}
.contacttitle{
  background: url("./assets/images/contact\ us.png") no-repeat;
  background-size: cover;
    background-position: top center;
    padding: 289px 0 223px 0;
}
.portfoliotitle{
    background: url("./assets/images/our-work.png") no-repeat;
    background-size: cover;
    background-position: top center;
    padding: 289px 0 223px 0;
}
.srvcdetails{
  
    background: url("./assets/images/our\ services.png") no-repeat;
    background-size: cover;
      background-position: top center;
      padding: 289px 0 223px 0;
  
}
/* .page-title .main-page h2.title:before{
  content: '';
  position: absolute;
  z-index: 1000;
  top: 73px;
  left: -17px;
  width: 22px;
  height: 22px;
  background: var(--main-color);
  border-radius: 50%;
} */

/* header line */
/* about */
h4.lineAB::before{
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  width: 136px;
  height: 2px;
  background: #9a2f6f;
  opacity: 1;
  transition: all 0.5s ease;
}
/* services */
h4.lineSER::before{
  content: '';
  position: absolute;
  top: -5px;
  width: 188px;
  height: 2px;
  background: #9a2f6f;
  opacity: 1;
  transition: all 0.5s ease;
}

/* clients */
h4.lineCL::before{
  content: '';
  content: '';
  position: absolute;
  top: -5px;
  width: 174px;
  height: 2px;
  background: #9a2f6f;
  opacity: 1;
  transition: all 0.5s ease;
}