
.portimg{
  width: 300px !important;
  height: 300px !important;
  border-radius: 10px;
  object-fit:cover;
  background-color: rgb(219, 219, 219);
}
/* .btn-close:hover{
  background: black;
} */