
/* Extra classes
-------------------------------------------------------------- */
/**
    *   None
    *   Padding
    *   Style Button
    *   Carousel Control
    *   Bullets Style
    *   Arrows Center
    *   Arrows Top
    *   Arrows Style 1
    *   Arrows Style 2
    *   Arrows & Bullets Circle
    *   Divider
    *   Pagination
    *   Box item Project
    *   Testimonials Item
    *   SlideBar
    *   Review
    *   Contact Form 7
    *   Style Form
    *   Box blog
    *   video
*/
/* None */
:root{
    --second-hover:#b9a8bb3b;
}
.no-margin {
    margin: 0px !important;
}
.no-padding { padding: 0px !important; }

.no-padding-top {
  padding-top: 0  !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}

/* Padding Top */
.padding-top-15 {
    padding-top: 15px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-35 {
    padding-top: 35px;
}
.padding-top-50 {
    padding-top: 50px;
}
.padding-top-70 {
    padding-top: 70px;
}
.padding-top-100 {
    padding-top: 100px;
}
.padding-top-120 {
    padding-top: 120px ;
}

/* Padding Bottom */

.padding-bottom-20 {
  padding-bottom: 20px;
}
.padding-bottom-30 {
  padding-bottom: 30px
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-60 {
    padding-bottom: 60px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
  padding-top: 0 !important;
}

/* Padding Right */

.padding-right-10 {
  padding-right: 10px;
}


/* Padding Left */
.padding-left-5 {
    padding-left: 5px;
  }
.padding-left-10 {
  padding-left: 10px;
}

.margin-right-10 {
    margin-right: 10px;
}

/* Padding Other */

.tf-section {
    padding: 0;
}
.secpadd{
    padding: 60px 0;
}

.z-index {
    position: relative;
    z-index: 99;
}
.background-none {
  background: none !important;
}

/* style Button */
.btn-contact {
    position: relative;
    z-index: 99;

    padding: 12px 37px 13px 50px;
    border-radius: 3px;
    background: #ffa800;
    color: #11161e;

    font-size: 18px;
    line-height: 2.667;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0px;
    border: none;
    overflow-y :hidden;
}
.btn-contact:hover::before {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.btn-contact:before {
    content: '';
    width: 34px;
    height: 100%;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    

    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    background: #d98f00;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}
.btn-contact span.more {
    position: relative;
    z-index: 99;
}
.btn-contact span.icon {
    position: absolute;
    left: -41px;
    top: -15px;
}

.btn-main {
    padding-top: 50px;
}
.btn-main a {
    position: relative;
    z-index: 99;

    padding: 21px 45px 21px 50px;
    border-radius: 3px;
    background: #e331ac;
    color: #11161e;

    font-size: 18px;
    line-height: 2.667;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0px;
    
}
.btn-main a span.more {
    position: relative;
    z-index: 99;
}
.btn-main a span.icon {
    position: absolute;
    left: -41px;
    top: -15px;
}
.dark a{
    background: #11161e;
    color: #fff;
}
.dark a::before {
    background: #ffa800 !important;
}
.btn-main a::before {
    content: '';
    width: 34px;
    height: 100%;
    border-bottom-right-radius: 50px;
    border-top-right-radius: 50px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    

    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    background: #b9a8bb74;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}
.btn-main a .fas {
    position: relative;
    z-index: 99;

}
.btn-main a .fa-plus:before {
    content: "\f067";
    font-size: 12px;
    font-family: "Font Awesome 5 Free";
    padding-right: 28px;
}

.btn-main a:hover:before {
    width: 100% !important;
    border-bottom-right-radius: 3px !important;
    border-top-right-radius: 3px !important;

    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    background: var(--second-hover);
    color: var(--main-color) !important;

    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
}
label.error {
    color: #11161e;
    font-size: 14px;
}
.bg-featured {
    background: #ffa800 !important;
}
.padding-btn-sl {
    padding-top: 285px !important;
    margin-left: -4px;
}


    
/* Carousel Control
-------------------------------------------------------------- */
.owl-theme .owl-nav, .owl-theme .owl-dots { display: none; }
.has-bullets .owl-theme .owl-dots { display: block; padding-top: 35px; }
.has-bullets.bullet24 .owl-theme .owl-dots { padding-top: 65px; }
.has-arrows .owl-theme .owl-nav { display: block; }
.has-arrows .owl-theme .owl-nav [class*='owl-'] { position: absolute; }
.has-arrows .owl-theme .owl-nav .owl-next { right: 0; }
/* Bullets Style */
.owl-theme .owl-dots { text-align: center; line-height: 10px; }
.owl-theme .owl-dots .owl-dot { display: inline-block; }
.owl-theme .owl-dots .owl-dot span { background-color: #11161e; display: block; width: 70px; height: 5px; margin: 0 5px;   -webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease;  }
.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot.active span { background-color: #ffa800; border-color:  #ffa800;  }

/* Arrows Center */
.has-arrows.arrow-center .owl-theme .owl-nav [class*='owl-'] { top: 50%; }
.has-arrows.arrow-center.offset-v-24 .owl-theme .owl-nav [class*='owl-'] { margin-top: -24px; }
.has-arrows.arrow-center.offset-v-60 .owl-theme .owl-nav [class*='owl-'] { margin-top: -60px; }
.has-arrows.arrow-center.offset-v-82 .owl-theme .owl-nav [class*='owl-'] { margin-top: -82px; }
.has-arrows.arrow-center.offset-v-111 .owl-theme .owl-nav [class*='owl-'] { margin-top: -111px; }

.has-arrows.arrow-center.offset-h-21 .owl-theme .owl-nav [class*='owl-'] { left: -21px; }
.has-arrows.arrow-center.offset-h-21 .owl-theme .owl-nav .owl-next { left: auto; right: -21px; }

/* Arrows Top */
.has-arrows.arrow-top .owl-theme .owl-nav [class*='owl-'] { right: 44px; }
.has-arrows.arrow-top .owl-theme .owl-nav .owl-next { right: 0; }
.has-arrows.arrow-top.arrow75 .owl-theme .owl-nav [class*='owl-'] { top: -75px; }

/* Arrows Style 1*/
.owl-theme .owl-nav [class*="owl-"] { text-align: center; background-color: #ffc30c; color: #fff;  width: 40px; height: 40px; font-size: 0; display: inline-block; position: relative; -webkit-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -ms-transition: all 0.3s ease; -o-transition: all 0.3s ease; transition: all 0.3s ease; }
.owl-theme .owl-nav [class*="owl-"]:after { font-size: 20px; line-height: 40px; content: "\f3d2"; font-family: "Ionicons"; position: absolute; left: 0; top: 0; text-indent: 0; width: 100%; height: 100%; text-align: center; display: inline-block;}
.owl-theme .owl-nav .owl-next:after { content: "\f3d3"; }
.owl-theme .owl-nav [class*="owl-"]:hover { background-color: #3e3e3e; }
.owl-theme .owl-nav [class*="owl-"].disabled:hover,
.owl-theme .owl-nav [class*="owl-"].disabled { background-color: rgba(255,255,255,0.2); color: #fff; cursor: default; }

/* Arrows Style 2*/
.arrow-style-2 .owl-theme .owl-nav [class*="owl-"].disabled { background-color: #e7e7e7; color: #bdbdbd; }

/* Arrows & Bullets Circle */
.arrow-circle .owl-theme .owl-nav [class*="owl-"],
.bullet-circle .owl-theme .owl-dots .owl-dot span { border-radius: 0%; }


/* divider */
.divider {
    position: relative;
    width: 370px;
    height: 9px;
    background: var(--second-hover);
    border-radius: 50px;
}
.divider.dark {
    background: #fff !important;
}
.divider.dark:after {
    background: rgba(17, 22, 30,0.2);
}
.divider::before {
    content: '';
    position: absolute;
    z-index: 9;
    top: 0;
    left: -1px;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    border-radius: 50px; 
}
.divider:after {
    content: '';
    position: absolute;
    z-index: 8;
    top: 4px;
    right: 10px;
    width: 200px;
    height: 1px;
    
}
.box-work .divider-left:before {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.box-work:hover .divider-left:before {
    height: 113px !important;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/*  Pagination*/
.pagination-project {
    text-align: center;
}
.pagination-project ul.list-pagination {
    margin: 0;
}
.pagination-project ul.list-pagination li {
    display: inline-block;
    width: 29px;
    height: 29px;
    border: 1px solid rgba(34, 34, 34, 0.07);
    border-radius: 3px;
    margin: 3px;
    background: #fff;
}
.pagination-project ul.list-pagination li:hover {
    background: #ffa800;
    border-color: #ffa800;
}
.pagination-project ul.list-pagination li:active {
    background: #ffa800;
    border-color: #ffa800;
}
.pagination-project ul.list-pagination li:hover a{
    color: #fff;
}
.pagination-project ul.list-pagination li:active a{
    color: #fff;
}
.pagination-project ul.list-pagination li a {
    font-size: 13px;
    color: #777777;
    font-weight: 400;
}


/* Box item Project */
.boxitem {
    position: relative;
    z-index: 99;
}
.boxitem .imgbox {
    height: 100%;
}
.boxitem .imgbox img {
    width: 100%;
    height: 100%;
}
.boxitem .box-item-content {
    
    z-index: 9;
    bottom: 20px;
    left: 0;
    width: 100%;
    border-radius: 10px;
    
}
.boxitem .box-item-content .content-box {
    background: #fff;
    color: #11161e;
    border-radius: 3px;
    width: 100%;
    height: auto;
    padding: 30px 25px;
    position: relative;
    z-index: 99;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease; }
.boxitem .box-item-content .content-box:hover {
    background: #11161e;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
.boxitem .box-item-content .content-box.no-hover:hover {
    background: #fff;
}
.boxitem .box-item-content .content-box.no-hover span {
    position: relative;
    z-index: 99;
}
.boxitem .box-item-content .content-box:hover a {
    color: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}
/* .boxitem .box-item-content .content-box:after {
    content: '';
    width: 37px;
    height: 66%;
    background: url("./../images/bg-after-box1.png") no-repeat;
    position: absolute;
    top: 0;
    right: -0; } */
.boxitem .box-item-content .content-box h6.subtitle {
    color: #777777;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    }
.boxitem .box-item-content .content-box h5.title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    z-index: 99;
    }
    .boxitem .box-item-content .content-box h5.title a {
        position: relative;
        z-index: 99;
    }
    /* .boxitem .box-item-content .content-box h5.title:before {
        content: '';
        position: absolute;
        z-index: 9;
        top: -3px;
        left: -9px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: var(--main-color);
} */

/* Testimonials Item */
.testimonials-item {
    background: #fff;
}
.testimonials-item .thumb {
    padding: 70px 20px 63px 30px;
}
.testimonials-item .thumb img {
    width: 98px !important;
    padding-bottom: 23px;
}
.testimonials-item .thumb .text {
    font-size: 24px;
    font-weight: 300;
    color: #11161e;
    text-decoration: underline;
    line-height: 1.333;
    padding-bottom: 45px;
    text-transform: none;
}
.testimonials-item .thumb .author {
    position: relative;
    z-index: 99;
    font-size: 18px;
    font-weight: bold;
    color: #11161e;
    letter-spacing: -0.1px;
    text-transform: uppercase;
    padding-left: 10px;
    padding-top: 8px;
}
.testimonials-item .thumb .author:before {
    content: '';
    position: absolute;
    z-index: 9;
    top: 5px;
    left: 0px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #ffa800;
}
.testimonials-item .thumb .author span {
    position: relative;
    z-index: 99;
}
.testimonials-item .thumb .category {
    color: #777777;
    padding-left: 10px;
    padding-top: 5px;
}
.category-featured {
    background: #ffa800 !important;
}
/* end */

/* SlideBar */
.slidebar {
    width: 31.5%;
}
.slidebar .box-author {
    text-align: center;
    background: #fff;
    padding: 48px 32px;
}
.slidebar .box-author h5.name {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    padding-top: 22px;
    padding-left: 6px;
}
.slidebar .box-author p.text {
    padding-top: 19px;
    font-size: 16px;
    letter-spacing: -0.2px;
    line-height: 1.7em;
}

.slidebar .box-author .img-author {
    position: relative;
}
.slidebar .box-author li.fb-author {
    position: absolute;
    top: 15%;
    left: 30%;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #11161e;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slidebar .box-author li.fb-author a {
    color: #ffa800;
}
.slidebar .box-author li.fb-author a i:before {
    content: '\f39e';
}
.slidebar .box-author li.fb-author:hover a {
    color: #fff;
}
.slidebar .box-author .list-social {
    margin: 0; }
    .slidebar .box-author .list-social li {
    position: relative;
    display: inline-block;
    padding: 0 30px; }
.slidebar .box-author .list-social li a {
    color: #777777;
}
.slidebar .box-author .list-social li a:hover {
    color: var(--main-color);
}
.slidebar .box-author .list-social li a:after {
    content: '';
    width: 2px;
    height: 11px;
    border-color: #777777;
    background: #777777;
    position: absolute;
    top: 7px;
    right: 0;
}
.slidebar .box-author .list-social li.none a::after {
    display: none;
}
.slidebar .box-author hr {
    margin: 23px 0;
}
.slidebar .box-search {
    margin-top: 50px;
    background: #fff;
    padding: 45px 27px;
}
.slidebar .box-search h5.title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 20px;
}
.slidebar .box-search .search-form {
    position: relative;
}
.slidebar .box-search .search-form input.header-search-field {
    font-size: 14px;
    font-weight: 400;
    background-color: #f3f3f4;
    padding: 15px 30px;
    border-radius: 8px;
    border-color: rgba(17, 22, 30, 0.1);
    width: 100%;
}
.slidebar .box-search .search-form button.header-search-submit {
    position: absolute;
    top: 20px;
    right: 20px;
    border: none;
    background: transparent;
    color: #e74901;
}
.slidebar .box-search .search-form button.header-search-submit i {
    font-size: 20px;
}
.slidebar .box-category {
    background: #fff;
    padding: 46px 28px 14px 28px;
}
.slidebar .box-category h5.title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}
.slidebar .box-category ul.list-category {
    
    padding-top: 15px;
}
.slidebar .box-category ul.list-category li {
    list-style: initial;
    padding-bottom: 10px;
}
.slidebar .box-category ul.list-category li::marker {
    color: #777777;
}
.slidebar .box-category ul.list-category li a {
    color: #777777;
    font-size: 16px !important;
}
.slidebar .box-category ul.list-category li a span.number {
    float: right;
    margin-top: -9px;
}
.slidebar .box-category ul.list-category li a:hover {
    color: var(--main-color);
}
.slidebar .themesflat-map-1 {
    margin-top: 50px;
    height: 550px;
    
}
.slidebar .themesflat-map-1 iframe {
    width: 100%;
    height: 100%;
}
/* end */

/* Review */
.review h5.title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 56px;
}
.review .review-content {
    position: relative;
    padding-top: 45px;
}
.review .review-content .img-rev {
    float: left;
}
.review .review-content .content {
    position: relative;
    padding-top: 23px;
    background: #fff;
    margin-left: 100px;
    padding-left: 65px;
    padding-right: 60px;
    padding-bottom: 21px;
}
.review .review-content .content p.text {
    margin-top: 19px;
    line-height: 1.78em;
}
.review .review-content .content .author {
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
}
.review .review-content .content .list-action {
    margin: 0;
}
.review .review-content .content .list-action li {
    display: inline-block;
    padding-right: 18px;
}
    .review .review-content .content .list-action li a {
    font-size: 13px;
    font-weight: 500;
    color: #777777;
}
.review .review-content .content .list-action li a:hover {
    color: #ffa800;
}
.review .review-content .content .list-action li a i:before {
    padding-right: 5px;
}
.review .review-content .content .list-star {
    position: absolute;
    top: 23px;
    right: 49px;
    margin: 0;
}
.review .review-content .content .list-star li {
    display: inline-block;
}
    .review .review-content .content .list-star li a {
    color: #ffa800;
}
.review .review-content .content .list-star li a i:before {
    margin-right: -2px;
}
.review .review-content .triangle-bottomright {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100px solid transparent;
}
.review .review-content.rep {
    padding-left: 100px;
}
.review .review-content.rep .content .list-star {
    display: none;
}
.review .review-content.rep .content .list-action li.clock {
    display: none;
}
.form-review {
    padding-top: 5px;
}
.form-review,.form-contact h5.title {
    font-size: 18px;
    font-weight: 500;
}

.form-review,.form-contact p span {
    color: #ff0101;
}
.form-review .list-star {
    margin: 0;
}
.form-review .list-star li {
    display: inline-block;
}
.form-review .list-star li a {
    color: #777777;
}
.form-review .list-star li a i:before {
    margin-right: -2px;
}
/* end */

/* Contact Form 7
-------------------------------------------------------------- */
.themesflat-contact-form .wpcf7-form-control-wrap { position: relative; display: block; }

.themesflat-contact-form .wpcf7-form-control-wrap.your-name,
.themesflat-contact-form .wpcf7-form-control-wrap.your-email,
.themesflat-contact-form .wpcf7-form-control-wrap.your-phone { margin-bottom: 14px; }


/* Style Form */
.themesflat-contact-form.style-2 {
    padding-top: 46px;
}
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name,
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-email,
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-phone,
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-subject {  width: 48%; float: left; margin-bottom: 20px; }
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-name   { margin-right: 30px; }
.themesflat-contact-form.style-2 .wpcf7-form-control-wrap.your-message { clear: both;     padding-bottom: 26px; }
.themesflat-contact-form.style-2 input,
.themesflat-contact-form.style-2 input,
.themesflat-contact-form.style-2 textarea {
    width: 100%;
    background-color: transparent;
    font-size: 13px;
    font-weight: 400;
    border: 1px solid var(--main-color);
    padding: 10px 30px;
    }
.themesflat-contact-form.style-2 textarea { height: 199px;padding-top: 19px; }
.themesflat-contact-form.style-2 input { padding: 19px 49px; margin-top: 20px; }

.themesflat-contact-form.w100 .wpcf7-form-control-wrap.your-name,
.themesflat-contact-form.w100 .wpcf7-form-control-wrap.your-email,
.themesflat-contact-form.w100 .wpcf7-form-control-wrap.your-phone { width: 100%; float: none; margin-right: 0; margin-bottom: 9px; }
.themesflat-contact-form.w100 textarea { height: 142px; }
.themesflat-contact-form.w100 input { margin-top: 9px; }



/* Box blog */
.themesflat-blog {
    position: relative;
    z-index: 9;
    background: #fff;
    padding: 10px;    
}
.themesflat-blog .img-blog {
    position: relative;
    z-index: 9;
    overflow: hidden;
}
.themesflat-blog .img-blog a.img-b:after {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient( to right, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0.0) 100% );
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}
.themesflat-blog:hover .img-blog a.img-b:after {
    opacity: 1;
    top: -20%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: linear;
}
.themesflat-blog .meta {
    position: absolute;
    z-index: 9;
    bottom: 103px;
    right: -86px;
    width: 66%;
    height: 40px;
    background: #fff;
    transform: rotate(-90deg);
    display: flex;
    align-items: center;
    justify-content: center;
}
.themesflat-blog .meta .time {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 32px;
}
.themesflat-blog .meta .comment a {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: #11161e;
}
.themesflat-blog .meta .comment a:hover {
    color: #ffa800;
}
.themesflat-blog .meta-featured {
    background: #ffa800;
}
.themesflat-blog .meta-featured .comment a:hover {
    color: #fff;
}
.themesflat-blog .content-blog {
    padding: 34px 20px;
}
.themesflat-blog .content-blog .category {
    background: #f3f3f4 ;
    padding: 6px 15px 5px 16px;
    color: #11161e;
    font-size: 14px;
    text-transform: uppercase; }
.themesflat-blog .content-blog .category:hover {
    color: #ffa800 ;
}
.themesflat-blog .content-blog .category-featured {
    background: #ffa800;
}
.themesflat-blog .content-blog .category-featured:hover {
    color: #fff;
}
.themesflat-blog .content-blog .title {
    padding-top: 19px;
    padding-bottom: 21px;
}
.themesflat-blog .content-blog .title a {
    font-size: 18px;
    font-weight: 500;
    color: #11161e;
    text-transform: uppercase;
    letter-spacing: 0px;
}
.themesflat-blog .content-blog .title a:hover {
    color: #ffa800;
}
.themesflat-blog .content-blog .text {
    font-weight: 400;
    color: #777777;
}
/* end */

/* video */
.box-video {
    margin-top: -252px;
    padding-left: 109px;
    padding-right: 91px;
}
.box-video .box {
    position: relative;
    background: url("./../assets/images/videocover.jpeg") no-repeat;
    background-size: cover;
    background-position: center center;
    border: 20px solid #fff;
    padding: 110px 0 350px 0;
    background-color: #cccccc;
    box-shadow: 0px 24px 60px 0px rgba(0, 0, 0, 0.05);
}
/* .box-video .box .overlay {
    background: #cccccc;
    opacity: 0.6;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
} */
/* .box-video .box:before {
    content: '';
    position: absolute;
    z-index: 12;
    top: -10px;
    left: -10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--main-color);
} */
.box-video .box .flat-video-fancybox {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 40%;
    width: 100%;
    text-align: center;
    padding: 0 227px;}
.box-video .box .flat-video-fancybox a.fancybox {
    display: inline-block;
    background: var(--main-color);
    width: 96px;
    height: 96px;
    border-radius: 50%;
    position: relative;
    z-index: 999;
    padding-top: 33px;
    padding-left: 5px;
}
.box-video .box .flat-video-fancybox  a.fancybox span.icon-play {
    font-size: 30px;
}
.box-video .box .flat-video-fancybox  a.fancybox:hover span.icon-play {
    color: #000000;
}
.box-video .box .flat-video-fancybox a.fancybox span i {
    font-size: 28px;
    padding-top: 33%;
    color: #fff;
}

.box-video .box .flat-video-fancybox .overlay-inner i {
    position: absolute;
    top: 23%;
    left: 45%;
    color: #777777;
    font-size: 20px;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    padding: 20px 40px;
    line-height: 60px;
    background: #ffa800;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.box-video .box .flat-video-fancybox .overlay-inner i:before {
    font-size: 24px;
}
.box-video .box .flat-video-fancybox .overlay-inner i:after {
    content: '';
    width: 96px;
    height: 96px;
    border: 2px solid black;
}
.box-video .box .flat-video-fancybox .box-content {
    background: var(--main-color);
    width: 100%;
    height: auto;
    text-align: center;
    margin-top: 49px;
    padding: 12px 0;
}
    .box-video .box .flat-video-fancybox .box-content .title-video {
    font-weight: 400;
    margin: 0;
    line-height: 1.25em;
    letter-spacing: -0.6px;
    text-transform: none;
}

.section-video.style-2 .box-video {
  margin-top: 0; }

.box-video.style-1 .box {
    background: url("../images/page/video-1.jpg") no-repeat;
    box-shadow: none;
    background-position: center center;
}
.box-video.style-1 .box .overlay {
    display: none;
}

.ripple,
.ripple:before,
.ripple:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 96px;
    height: 96px;
    border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    -webkit-animation: ripple 3s infinite;
    -moz-animation: ripple 3s infinite;
    -ms-animation: ripple 3s infinite;
    -o-animation: ripple 3s infinite;
    animation: ripple 3s infinite;
}
.ripple:before {
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s;
    -ms-animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    animation-delay: 0.9s;
    content: "";
    position: absolute;
}
.ripple:after {
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    animation-delay: 0.6s;
    content: "";
    position: absolute;
}
.up-down {
    animation-name: up-down;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: up-down;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: up-down;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: up-down;
    -ms-animation-duration: 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: up-down;
    -o-animation-duration: 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;
}
/* end */