@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?f3b3ji');
  src:  url('../fonts/icomoon.eot?f3b3ji#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?f3b3ji') format('truetype'),
    url('../fonts/icomoon.woff?f3b3ji') format('woff'),
    url('../fonts/icomoon.svg?f3b3ji#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-channel:before {
  content: "\e907";
}
.icon-stats:before {
  content: "\e908";
}
.icon-app:before {
  content: "\e900";
}
.icon-chart:before {
  content: "\e901";
}
.icon-development:before {
  content: "\e902";
}
.icon-graphic-design:before {
  content: "\e903";
}
.icon-phone-ringing:before {
  content: "\e904";
}
.icon-play:before {
  content: "\e905";
}
.icon-play-button:before {
  content: "\e906";
}
