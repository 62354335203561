.img-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
    opacity: 0;
    border-radius: 10px;
}
.project-item:hover .img-overlay,
.project-item:focus .img-overlay{
        opacity: 1;
        
}
